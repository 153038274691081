/* eslint-disable react-hooks/exhaustive-deps */
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSearch,
  faHeart,
  faComment,
  faLock,
  faCog,
  faSignInAlt,
  faMoon,
  faSun,
  faShoppingCart,
  faCheckCircle,
  faUserTimes,
} from "@fortawesome/free-solid-svg-icons";
import { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { Cross, Profile, neuDaddyLogo } from "../../assets/images";
import "./style.css";
import { useDispatch, useSelector } from "react-redux";
import { ACTIONS } from "../../redux/actions";
import { RootReducer } from "../../interfaces/reducer";
import { Link, useLocation, useNavigate } from "react-router-dom";
import ROUTES from "../../constants/routes";
import { UserAction } from "../../redux/thunk";
import { AppDispatch } from "../../redux/store";
import { HEADER, THEME } from "../../utils/constants";
import DeleteAccountComponent from "../Modals/Profile/delete";
import LogoutComponent from "../Modals/Profile/LogoutComponent";

interface Props extends DispatchToProps {}

const Header = (props: Props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLogoutModalOpen, setIsLogoutModalOpen] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { searchActive } = useSelector((state: RootReducer) => state.members);
  const { theme } = useSelector((state: RootReducer) => state.common);
  const { profile } = useSelector((state: RootReducer) => state.user);
  const { authenticated, redirectToHome, fullName } = useSelector(
    (state: RootReducer) => state.auth
  );
  const [activePanel, setActivePanel] = useState<String>("");
  const { activeHeaderOption } = useSelector(
    (state: RootReducer) => state.common
  );
  const [showToggle, setShowToggle] = useState(false);

  const handleLoginModal = (status: boolean) => {
    dispatch({ type: ACTIONS.SET_LOGIN_MODAL, status });
  };

  useEffect(() => {
    setActivePanel(activeHeaderOption);
  }, [activeHeaderOption]);

  useEffect(() => {
    if (authenticated && redirectToHome && location.pathname === ROUTES.HOME) {
      navigate(ROUTES.MEMBERS);
    }
    if (
      ![
        ROUTES.CONTACTUS,
        ROUTES.ABOUTUS,
        ROUTES.TERMCONDITIONS,
        ROUTES.PRIVACYP,
      ].includes(location.pathname)
    ) {
      props.getProfile();
    }
  }, [authenticated]);

  const handleSearchClick = () => {
    dispatch({ type: ACTIONS.SET_SEARCH, search: !searchActive });
    if (location.pathname !== ROUTES.MEMBERS) {
      navigate(ROUTES.MEMBERS);
    }
    dispatch({ type: ACTIONS.SET_HEADER_OPTION, option: HEADER.SEARCH });
  };

  const handleInterestsClick = () => {
    navigate(ROUTES.INTERESTS);
    dispatch({ type: ACTIONS.SET_HEADER_OPTION, option: HEADER.INTERESTS });
  };

  const handleMessagesClick = () => {
    navigate(ROUTES.MESSAGES);
    dispatch({ type: ACTIONS.SET_HEADER_OPTION, option: HEADER.MESSAGES });
  };

  const handleDeleteAccount = (event: React.MouseEvent) => {
    setIsModalOpen(true);
  };

  const handleLogout = () => {
    setIsLogoutModalOpen(true);
  };

  return (
    <header
      className={`headser-mian ${
        location.pathname !== ROUTES.HOME ? "loginHeader" : ""
      }`}
    >
      <div className="container">
        <nav className="navbar nav-header navbar-expand-lg navbar-width">
          <div className="container-fluid">
            <a href={ROUTES.MEMBERS} className="navbar-brand cursor-pointer">
              <img
                src={neuDaddyLogo}
                alt=""
                className="img-fluid text-center mx-auto me-2-header"
              />
            </a>

            {authenticated ? (
              <div
                className={`collapse-mob ${showToggle ? "sidebar-toggle" : ""}`}
              >
                <img
                  onClick={() => setShowToggle(false)}
                  src={Cross}
                  alt=""
                  className="img-fluid mob-show sidebar cross-b"
                />
                <ul className="navbar-nav top-nav second-nav">
                  <li
                    className={`nav-item ${
                      activePanel === HEADER.SEARCH ? "active" : ""
                    }`}
                  >
                    <a className="nav-link" onClick={handleSearchClick}>
                      <FontAwesomeIcon icon={faSearch} />
                      Search
                    </a>
                  </li>
                  <li
                    className={`nav-item ${
                      activePanel === HEADER.INTERESTS ? "active" : ""
                    }`}
                  >
                    <a className="nav-link" onClick={handleInterestsClick}>
                      <FontAwesomeIcon icon={faHeart} />
                      Interests
                    </a>
                  </li>
                  <li
                    className={`nav-item  ${
                      activePanel === HEADER.MESSAGES ? "active" : ""
                    }`}
                  >
                    <a className="nav-link" onClick={handleMessagesClick}>
                      <FontAwesomeIcon icon={faComment} />
                      Messages
                    </a>
                  </li>
                </ul>
              </div>
            ) : (
              <div
                className={`collapse-mob ${showToggle ? "sidebar-toggle" : ""}`}
              >
                <img
                  onClick={() => setShowToggle(false)}
                  src={Cross}
                  alt=""
                  className="img-fluid mob-show sidebar cross-b"
                />
                <ul className="navbar-nav top-nav second-nav">
                  <li
                    className={`nav-item  ${
                      location.pathname === ROUTES.HOME ? "active" : ""
                    }`}
                  >
                    <Link className="nav-link" to={ROUTES.HOME}>
                      Home
                    </Link>
                  </li>
                  <li
                    className={`nav-item  ${
                      location.pathname === ROUTES.ABOUTUS ? "active" : ""
                    }`}
                  >
                    <Link className="nav-link" to={ROUTES.ABOUTUS}>
                      About us
                    </Link>
                  </li>
                  <li
                    className={`nav-item  ${
                      location.pathname === ROUTES.CONTACTUS ? "active" : ""
                    }`}
                  >
                    <Link className="nav-link" to={ROUTES.CONTACTUS}>
                      Contact us
                    </Link>
                  </li>
                  <li
                    className={`nav-item  ${
                      location.pathname === ROUTES.TERMCONDITIONS
                        ? "active"
                        : ""
                    }`}
                  >
                    <Link className="nav-link" to={ROUTES.TERMCONDITIONS}>
                      Terms & Condition
                    </Link>
                  </li>
                  <li
                    className={`nav-item  ${
                      location.pathname === ROUTES.PRIVACYP ? "active" : ""
                    }`}
                  >
                    <Link className="nav-link" to={ROUTES.PRIVACYP}>
                      Privacy Policy
                    </Link>
                  </li>
                </ul>
              </div>
            )}
            <div className="d-flex gap-3">
              <div className="login mob-hide cursor-pointer d-flex">
                {authenticated ? (
                  <></>
                ) : (
                  <p
                    onClick={() => handleLoginModal(true)}
                    className="add-vibe"
                  >
                    Sign Up / Log In
                  </p>
                )}
                {authenticated ? (
                  <div className="bts-sec">
                    <div className="dropdown drop-btn">
                      <button
                        className="border-0 btn-top dropdown-toggle"
                        type="button"
                        id="dropdownMenu"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <div className="profile-wid">
                          <img
                            src={
                              profile?.profile_image &&
                              profile?.profile_image != ""
                                ? profile?.profile_image
                                : Profile
                            }
                            alt=""
                            className="img-fluid"
                          />
                        </div>
                        <span className="btn-name">{fullName}</span>
                      </button>
                      <ul
                        className="dark-drop dropdown-menu top-drop-menu"
                        aria-labelledby="dropdownMenu"
                      >
                        <li className="pad-16">
                          <div className="profile-change">
                            <div className="img-sec">
                              <img
                                src={
                                  profile?.profile_image &&
                                  profile?.profile_image != ""
                                    ? profile?.profile_image
                                    : Profile
                                }
                                alt=""
                                className="img-fluid"
                              />
                            </div>
                            <div className="text-sec">
                              <h3>{fullName}</h3>
                            </div>
                          </div>
                          <div className="btn-space btn btn-c edit-profile-b">
                            <a href={ROUTES.USER_PROFILE}>Edit Profile</a>
                          </div>
                        </li>
                        <li className="p-16">
                          <a
                            onClick={() => {
                              dispatch({
                                type: ACTIONS.SET_THEME,
                                theme:
                                  theme === THEME.LIGHT
                                    ? THEME.DARK
                                    : THEME.LIGHT,
                              });
                            }}
                            className="dropdown-item text-decoration-none"
                          >
                            <FontAwesomeIcon
                              icon={theme === THEME.LIGHT ? faMoon : faSun}
                            />
                            {`Turn on ${
                              theme === THEME.LIGHT ? THEME.DARK : THEME.LIGHT
                            } mode`}
                          </a>
                        </li>
                        <li className="p-16">
                          <a
                            href="/subscriptions"
                            className="dropdown-item text-decoration-none"
                          >
                            <FontAwesomeIcon icon={faCheckCircle} />
                            Subscription
                          </a>
                        </li>
                        <li className="p-16">
                          <a
                            onClick={() => {
                              dispatch({
                                type: ACTIONS.SET_PASSWORD_MODAL,
                                status: true,
                              });
                            }}
                            className="dropdown-item text-decoration-none"
                          >
                            <FontAwesomeIcon icon={faLock} />
                            Change Password
                          </a>
                        </li>

                        <li className="p-16">
                          <Link
                            to={ROUTES.SETTING}
                            className="dropdown-item text-decoration-none"
                          >
                            <FontAwesomeIcon icon={faCog} />
                            Setting
                          </Link>
                        </li>
                        <li className="p-16">
                          <a
                            onClick={handleLogout}
                            className="dropdown-item text-decoration-none"
                          >
                            <FontAwesomeIcon
                              icon={faSignInAlt}
                              className="pr-4"
                            />
                            Log Out
                          </a>
                        </li>
                        <li className="p-16">
                          <a
                            className="dropdown-item text-decoration-none"
                            onClick={handleDeleteAccount}
                          >
                            <FontAwesomeIcon icon={faUserTimes} />
                            Delete account
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                ) : null}
              </div>
              <button
                onClick={() => setShowToggle((prev) => !prev)}
                className="navbar-toggler sidebar"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span>
                  <i className="fa fa-bars text-dark" aria-hidden="true"></i>
                </span>
              </button>
            </div>
          </div>
        </nav>
      </div>

      <DeleteAccountComponent
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      />
      <LogoutComponent
        isOpen={isLogoutModalOpen}
        onClose={() => setIsLogoutModalOpen(false)}
      />
    </header>
  );
};

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  getProfile: () => dispatch(UserAction.getProfileDetails()),
});

interface DispatchToProps {
  getProfile: () => void;
}

export default connect(null, mapDispatchToProps)(Header);
