import { useNavigate } from "react-router-dom";
import ROUTES from "../../constants/routes";
import Women from "../../assets/images/femaleProfile@3x.png";
import Men from "../../assets/images/maleProfile@3x.png";
import Common from "../../assets/images/dummyProfile.png";
import { useSelector } from "react-redux";
import { Constants } from "../../utils/constants";
import ReactPagination from "../UI/Pagination/ReactPagination";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart } from "@fortawesome/free-solid-svg-icons";

const MemberListing = ({ values, getUsers, handleFvt }) => {
  const navigate = useNavigate();
  const { filteredMembersList, filteredMembersLastPage } = useSelector(
    (state) => state.members
  );

  return (
    <>
      {filteredMembersList?.length > 0 ? (
        <ul className="nav row listing-5">
          {filteredMembersList?.map((member, index) => {
            return (
              <li key={index}>
                <div key={member.id} className="list-img-size cursor-pointer">
                  <img
                    onClick={() => {
                      navigate(
                        `${ROUTES.USER_PROFILE}/${member?.filters?.user_id}`
                      );
                    }}
                    src={
                      member?.is_profile_image_approved
                        ? member.profile_image
                        : member?.gender === 1
                        ? Women
                        : member?.gender === 2
                        ? Men
                        : Common
                    }
                    alt=""
                    onError={(e) => {
                      const target = e.target;
                      target.onerror = null;
                      target.src = Common;
                    }}
                  />
                  <div className="btn_flexs">
                    <button
                      className="btn btn-heart user-list"
                      style={{ zIndex: 9 }}
                      onClick={() => handleFvt(member)}
                    >
                      {member?.is_favourite === 1 ? (
                        <FontAwesomeIcon
                          icon={faHeart}
                          style={{
                            color: "red",
                            width: "14px",
                            height: "14px",
                          }}
                        />
                      ) : (
                        <FontAwesomeIcon
                          icon={faHeart}
                          style={{
                            color: "white",
                            width: "14px",
                            height: "14px",
                          }}
                        />
                      )}
                    </button>
                    <button
                      type="button"
                      className="btn btn-chat user-list"
                      onClick={() => navigate(`/chat/${member?.id}`)}
                    >
                      <i className="far fa-comment-dots"></i>
                    </button>
                  </div>
                  <div className="n-adress">
                    <h4>
                      {member?.is_name_approved
                        ? member.user_name
                        : Constants.newUser}
                    </h4>
                    <p>
                      {member.age} - {member.location}
                    </p>
                    <h6>{member.photos?.length} - Photos</h6>
                  </div>
                </div>
              </li>
            );
          })}
        </ul>
      ) : (
        <p className="text-center">No member found</p>
      )}
      {filteredMembersLastPage > 1 && (
        <ReactPagination
          totalPage={filteredMembersLastPage}
          newOffset={(offset) => {
            let payload = {
              ...values,
              page: offset,
            };
            getUsers(payload);
          }}
        />
      )}
    </>
  );
};

export default MemberListing;
